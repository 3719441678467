import React, { useState } from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import useScript from "../service/useScript"

const HowItWorks = ({ data: { allNodeTemplatePages: { edges } } }) => {

    const pageData = edges?.[0];
    const metaTag = [];

    const pageContent = pageData?.node?.relationships?.field_component_type;
    const banner = pageContent?.[0];
    const bannerTitle = banner?.title || "";
    const bannerSubtitle = banner?.subtitle || "";
    const bannerAlt = banner?.bannerImage?.alt || "Hinduja Hospital";
    const bannerPic = banner?.relationships?.bannerImage?.uri?.url;
    const mobileBannerPic = banner?.relationships?.mobileBannerImage?.uri?.url || bannerPic;
    const bannerLinkText = banner?.bannerLink?.title || "";
    const bannerLinkUrl = banner?.bannerLink?.uri

    const line = pageContent?.[1]?.htmlText?.processed || "";

    const iconList = pageContent?.[2] && pageContent?.[2]?.relationships?.components;

    const videoSection = pageContent?.[3];
    const videoContent = videoSection?.description?.processed;
    const videoAlt = videoSection?.image?.alt;
    const videoImage = videoSection?.relationships?.image?.uri?.url
    const video = videoSection?.relationships?.field_pdf_upload?.uri?.url;

    const article = pageContent?.[4];
    const articleTitle = article?.field_title || ""
    const description = article?.text?.processed || ""
    const buttonText = article?.field_cta_link?.title || "";
    let buttonLink = article?.field_cta_link?.uri;
    const extraText = article?.field_text?.processed || ""

    //converting the button 
    if(buttonLink) buttonLink = buttonLink?.replace("internal:", "");


    const accordian = pageContent?.[5]?.relationships?.components;

    const benifitsSection = pageContent?.[6];
    const benifitsTitle = benifitsSection?.field_title || "";
    const benifits = benifitsSection?.relationships?.field_components || "";

    const faqSection = pageContent?.[7];
    const faqTitle = faqSection?.field_title || "";
    const faqLink = faqSection?.field_link?.uri;
    const faqButtonText = faqSection?.field_link?.title || "";
    const faqAccord = faqSection?.relationships?.field_components;

    useScript("/assets/js/how-it-works.js")



    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/services.css", "/assets/css/accessibility.css"],
                    }}
                    tags={metaTag}
                />
                {pageContent?.length 
                ?
                <main className="innerpage">
                    <section className="inner_common_banner">
                        <picture className="banner_img">
                            <img src={mobileBannerPic} alt={bannerAlt} className="d-md-none"/>
                            <img src={bannerPic} alt={bannerAlt} className="d-md-block d-none"/>
                        </picture>
                        <div className="banner_content text_left text_dark">
                            <div className="container">
                                <h1>{bannerTitle?.length > 0 ? bannerTitle : "Save Time. Healthcare Online."}</h1>
                                <p className="subhead">
                                    {bannerSubtitle?.length > 0 ? bannerSubtitle : "Bringing healthcare consultation to you."}
                                </p>
                                <p className="mt-4">
                                    <a href={bannerLinkUrl?.length > 0 ? bannerLinkUrl : "/"} className="btn btn-primary">
                                        {bannerLinkText?.length > 0 ? bannerLinkText : "Book your tele consultation now"}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className="section_how_it_works section-py">
                        <div className="container">
                            <div dangerouslySetInnerHTML={{ __html: line }} />
                            <ul className="steps_list">
                                {
                                    iconList?.map((el, index) => {
                                        return (
                                            <li key={index}>
                                                <figure>
                                                    <img
                                                        src={el?.relationships?.image?.uri?.url}
                                                        alt={el?.image?.alt}
                                                    />
                                                </figure>
                                                <strong>{el?.title?.length > 0 ? el?.title : `STEP ${index + 1}`}</strong>
                                                <div dangerouslySetInnerHTML={{ __html: el?.description?.processed }} />

                                            </li>
                                        )
                                    })
                                }

                            </ul>
                        </div>
                    </section>
                    <section className="online_doctor_consultation section-py section-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="video_wrapper">
                                        {
                                            video?.length > 0 ? (
                                                <div style={{ position: "relative" }}>
                                                    <video width="500" height="300" controls>
                                                        <source src={video} type="video/mp4"></source>
                                                    </video>
                                                </div>
                                            ) : (<img src={videoImage} alt={videoAlt} />)

                                        }
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: videoContent }} />
                                </div>
                                <div className="col-md-6">
                                    <div className="heading-section">
                                        <h2 className="section-heading-2">
                                            {articleTitle?.length > 0 ? articleTitle : "Experience Online Doctor Consultation"}
                                        </h2>
                                        <p className="heading_text" dangerouslySetInnerHTML={{ __html: description }}>

                                        </p>
                                    </div>
                                    <p className="btn_wrap mb-4">
                                        <a href={buttonLink} className="btn btn-primary">
                                            {buttonText}
                                        </a>
                                    </p>
                                    <p dangerouslySetInnerHTML={{ __html: extraText }} />
                                </div>
                            </div>
                            <div className="accordion_container mt-4">
                                <div
                                    id="accordion_vc"
                                    role="tablist"
                                    className="common_accordion specialty_accordion"
                                >
                                    {
                                        accordian.map((el, index) => {

                                            return (
                                                <div className="card" key={index}>
                                                    <div className="card-header" role="tab" id="heading_q1">
                                                        <h5 className="mb-0">
                                                            <a
                                                                className="collapsed"
                                                                data-toggle="collapse"
                                                                href={`#vc_0${index + 1}`}
                                                                aria-expanded="false"
                                                                aria-controls="vc_01"
                                                            >
                                                                {el?.field_title}
                                                            </a>
                                                        </h5>
                                                    </div>

                                                    <div
                                                        id={`vc_0${index + 1}`}
                                                        className="collapse"
                                                        role="tabpanel"
                                                        aria-labelledby={`heading_q${index + 1}`}
                                                    >
                                                        <div className="card-body">
                                                            <div dangerouslySetInnerHTML={{ __html: el?.text?.processed }} />
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </section >
                    <section className="services_section common_services three_cols section-py" >
                        <div className="container">
                            <h2>{benifitsTitle?.length > 0 ? benifitsTitle : "Benefits of Online Consultation"}</h2>
                            <div className="row">

                                {
                                    benifits.map((el, index) => {
                                        return (
                                            <div className="col-md-4" key={index}>
                                                <div className="service_card">
                                                    <h3>{el?.field_title}</h3>
                                                    <div dangerouslySetInnerHTML={{ __html: el?.text?.processed }} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </section>
                    <section className="section-py section-bg">
                        <div className="container">
                            <h2 className="text-center mb-4">{faqTitle}</h2>
                            <div className="accordion_container">
                                <div
                                    id="accordion"
                                    role="tablist"
                                    className="common_accordion specialty_accordion"
                                >
                                    {
                                        faqAccord.map((el, index) => {
                                            return (
                                                <div className="card">
                                                    <div className="card-header" role="tab" id={`heading_q${index + 1}`}>
                                                        <h5 className="mb-0">
                                                            <a
                                                                className="collapsed"
                                                                data-toggle="collapse"
                                                                href={`#faq_0${index + 1}`}
                                                                aria-expanded="false"
                                                                aria-controls="faq_01"
                                                            >
                                                                {el?.field_title}
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div
                                                        id={`faq_0${index}`}
                                                        className="collapse"
                                                        role="tabpanel"
                                                        aria-labelledby="heading_q1"
                                                        data-parent="#accordion"
                                                    >
                                                        <div className="card-body">
                                                            <div dangerouslySetInnerHTML={{ __html: el?.text?.processed }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p className="btn_wrap text-center mt-4">
                                    <a href={faqLink} className="btn btn-primary">
                                        {faqButtonText}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </section>
                </main >
                :   <div className="container">
                        <p className="py-5 mb-0">No Data Available.</p>
                </div>}
            </Layout >
        </>
    )
}


export const query = graphql`
query patientCareHowItWorks {

    allNodeTemplatePages(filter: {path: {alias: {regex: "/how-it-works$/"}}}) {

      edges {

        node {

          id

          title

          path {

            alias

          }

          metatag {
            attributes {
                content
                href
                name
                property
                rel
            }
          }

          relationships {

            field_component_type {

              __typename

              ...ParagraphBannerworks

              ...ParagraphHTMLText

              ...ParagraphImageWithTitleAndDescriptionworks

              ...ParagraphTitleAndDescriptionworks

              ...ParagraphSectionworks

              ... on paragraph__title_and_description_components {

                id

                field_title

                field_link {

                  uri

                  title

                }

                relationships {

                  field_components {

                    ...ParagraphTitleAndDescriptionworks

                  }

                }

              }

            }

          }

        }

      }

    }

  }

 

  fragment ParagraphSectionworks on paragraph__section {

    id

    name: field_name

    relationships {

      components: field_components {

        __typename

        ...ParagraphHTMLText

        ...ParagraphImageWithTitleAndDescriptionworks

        ...ParagraphImageTitleDescLinkworks

        ...ParagraphTitleAndDescriptionworks

      }

    }

  }

 

  fragment ParagraphImageTitleDescLinkworks on paragraph__image_title_desc_link {

    id

    title: field_title

    field_title_1

    description: field_description {

      processed

    }

    link: field_link {

      uri

      title

    }

    relationships {

      image: field_image {

        id

        uri {

            value

            url

        }

      }

    }

  }

 

  fragment ParagraphImageWithTitleAndDescriptionworks on paragraph__image_with_title_and_description {

    id

    title: field_title

    description: field_content {

      processed

    }

    image: field_image {

      alt

    }

    alignment: field_alignment

    relationships {

      image: field_image {

        id

        uri {

            value

            url

        }

      }

      field_pdf_upload {

        uri {

            value

            url

        }

      }

    }

  }

 

  fragment ParagraphTitleAndDescriptionworks on paragraph__title_and_description {

    id

    field_title

    text: field_description {

      processed

    }

    field_cta_link {

      title

      uri

    }

    field_text {

      processed

    }

  }

 

  fragment ParagraphBannerworks on paragraph__banner {

     id

  title: field_title

  subtitle: field_subtitle

  bannerImage: field_banner_image {

    alt

  }

  mobileBannerImage: field_mobile_banner {

    alt

  }

  bannerLink: field_banner_link {

    uri

    title

  }

  relationships {

    bannerImage: field_banner_image {

      id

      uri {

        value

        url

      }

    }

    mobileBannerImage: field_mobile_banner {

      id

      uri {

        url

        value

      }

    }

  }

  }
`


export default HowItWorks